import Vue from 'vue'
import User from 'Components/User'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById("user-show")
  if(el) {
    const props = JSON.parse(el.dataset.props)
    if(el) {
      new Vue({
        el: el,
        render: h => h(User, {
          props
        })
      })
    }
  }
})
