<template>
  <div>
    <div v-if="user.id">
      <div class="columns" >
        <div class="column is-6">
          <h4>Name & Shipping Info</h4>
          <p>Name:<br>
          {{user.name}}
          </p>
          <p>Email:<br>
          {{user.email}}
          </p>

          <p>Do you want to ship or pickup these items?</p>
          <div class="control">
            <label class="radio">
              <input type="radio" v-model="shipping" value="ship" name="ship">
              Ship
            </label>
            <label class="radio">
              <input type="radio" v-model="shipping" value="pickup" name="ship">
              Pickup
            </label>
          </div>
          <span v-if="taxRate > 0">
            <input v-if="selectedAddress.has_tax" type="hidden" name="order[tax_id]" :value="selectedAddress.tax.id" />
            <input type="hidden" name="order[tax_rate]" :value="taxRate" />
            <input type="hidden" name="order[tax_amount]" :value="taxAmount" />
          </span>
          <div v-if="shipping == 'ship'">
            <p>Ship To:</p>
            <input type="hidden" name="order[address_id]" :value="selectedAddress.id" />
            <input type="hidden" name="order[shipping_quote_title]" :value="shippingQuote.title" />
            <input type="hidden" name="order[shipping_quote_number]" :value="shippingQuote.number" />
            <input type="hidden" name="order[shipping_amount]" :value="shippingQuote.amount" />
            <input type="hidden" name="order[shipping_quote_amount]" :value="shippingQuote.quote_amount" />
            <label v-for="address in user.addresses" class="radio">
                <input type="radio" :value="address" v-model="selectedAddress" name="address">
                {{address.summary}}
              </label>
            </label>
          </div>
        </div>
        <div class="column is-5 is-offset-1">
          <table class="table" style="width: 100%;">
            <tr>
              <th>Cart Subtotal:</th>
              <td class="text-right">{{subtotal_f}}</td>
            </tr>
            <tr v-if="shipping == 'ship'">
              <th>Shipping and Handling:</th>
              <td class="text-right">
                <span v-if="state != 'fetching_quote'">{{shippingQuote.amount_f}}</span>
                <span v-if="state == 'fetching_quote'">Getting Quote..</span>

              </td>
            </tr>
            <tr>
              <th>Tax: <span>({{taxRate_f}})</span></th>
              <td class="text-right">
                <span>${{taxAmount}}</span>
              </td>
            </tr>
            <tr>
              <th>Total:</th>
              <th class="text-right">${{totalAmountF}}</th>
            </tr>

          </table>
        </div>
      </div>

      <div v-if="shipping == 'ship'"><p><a href="#" @click.prevent="state = 'addingAddress'">Add New Address</a></p></div>
      <div v-if="state == 'addingAddress'" >
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label" for="">Ship To Name:</label>
              <div class="control"><input type="text" v-model="newAddress.name" class="input" /></div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label" for="">Street</label>
              <div class="control"><input type="text" v-model="newAddress.address" class="input" /></div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label" for="">Street 2</label>
              <div class="control"><input type="text" v-model="newAddress.address2" class="input" /></div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label" for="">City</label>
              <div class="control"><input type="text" v-model="newAddress.city" class="input" /></div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label" for="">State</label>
              <div class="control"><input type="text" v-model="newAddress.state" class="input" /></div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label" for="">Zip</label>
              <div class="control"><input type="text" v-model="newAddress.zip" class="input" /></div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label" for="">Notes/Comments:</label>
              <div class="control"><textarea v-model="newAddress.comments" class="textarea"></textarea></div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <button @click="saveAddress" type="button" class="button is-primary">Save Address</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "custom/api";
import _ from "lodash";
export default {
  props: ['subtotal', 'subtotal_f', 'total_weight', 'total_slabs'],
  data() {
    return {
      state: 'idle',
      shipping: 'ship',
      user: {},
      newAddress: {},
      selectedAddress: {},
      shippingQuote: {},
      address_id: null,
    }
  },
  watch: {
    "selectedAddress.id": function() {
      if(this.selectedAddress.id) {
        this.state = 'fetching_quote'
        Api().get("/shipping-quote", {params: {
          weight: this.total_weight,
          zip: this.selectedAddress.zip,
          city: this.selectedAddress.city,
          state: this.selectedAddress.state,
          total_slabs: this.total_slabs
        }}).then(r => {
          this.shippingQuote = r.data
          this.state = 'idle'
        }).catch(r => {
          alert(r.response.data.string)
        })
      }
    },
    shipping: {
      immediate: true,
      handler() {
        if(this.shipping == 'ship') {
          $(".js-cash_on_pickup").hide()
        } else {
          $(".js-cash_on_pickup").show()
        }
      }
    }
  },
  computed: {
    hasSelectedAddress() {
      if(this.selectedAddress.id) {
        return true;
      } else {
        return false;
      }
    },
    shippingAmount() {
      if(this.shippingQuote.amount) {
        return _.round(this.shippingQuote.amount, 2)
      } else {
        return 0;
      }
    },
    taxRate_f() {
      if(this.shipping == 'pickup') {
        return "9.3%"
      }
      else if(this.selectedAddress.has_tax) {
        return this.selectedAddress.tax.rate_f
      }
      else {
        return "0%"
      }
    },
    taxRate() {
      if(this.shipping == 'pickup') {
        return 0.093
      }
      else if(this.selectedAddress.has_tax) {
        return this.selectedAddress.tax.rate
      }
      else {
        return 0
      }
    },
    taxAmount() {
      return _.round(this.subtotal * this.taxRate, 2)
    },
    totalAmount() {
      let shipAmt = this.shippingAmount
      if(this.shipping == 'pickup') {
        shipAmt = 0
      }
      return _.round(this.subtotal + this.taxAmount + shipAmt, 2)
    },
    totalAmountF() {
      return parseFloat(this.totalAmount).toFixed(2)
    }
  },
  methods: {
    saveAddress() {
      this.state = 'saving_address'
      let data = {
        address: this.newAddress
      }
      Api().post("/addresses", data)
        .then((r) => {
          this.address_id = r.data.id
          this.selectedAddress = r.data
          this.fetchUser();
          this.state = 'idle'
          this.resetAddress()
        })
    },
    resetAddress() {
      this.newAddress = {
        name: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      }
    },
    fetchUser() {
      this.state = 'loading'
      Api().get("/users/me")
        .then((response) => {
          this.user = response.data
          this.state = 'idle'
          if(response.data.user_present) {
            if(this.address_id) {
              this.selectedAddress = response.data.addresses.filter(a => a.id == this.address_id)[0]
            } else {
              if(response.data.addresses.length) {
                this.selectedAddress = response.data.addresses[0]
              }
            }
            this.newAddress.name = this.user.name
          }
        })
    }
  },
  mounted() {
    this.fetchUser()
    this.resetAddress()
  }
}
</script>

<style lang="scss" scoped>
  label.radio {
    display: inline-block;
    input[type='radio'] {
      display: inline;
    }
  }

</style>
